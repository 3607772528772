dmx.animate = function (node, effect, duration, delay) {
  if (node.cancelAnimate) node.cancelAnimate();

  dmx.animate.clear(node);

  return new Promise((resolve, reject) => {
    const className = `animate__${effect}`;
    const complete = (event) => {
      node.cancelAnimate = null;
      event.stopPropagation();
      dmx.animate.clear(node);
      resolve();
    }

    node.cancelAnimate = () => {
      node.removeEventListener('animationend', complete);
      dmx.animate.clear(node);
      reject();
    };
    
    if (delay) node.style.setProperty('--animate-delay', delay + 'ms');
    if (duration) node.style.setProperty('--animate-duration', duration + 'ms');

    node.classList.add('animate__animated', className);

    node.addEventListener('animationend', complete, { once: true });
  });
};

dmx.animate.clear = function (node) {
  node.style.removeProperty('--animation-delay');
  node.style.removeProperty('--animation-duration');
  node.classList.remove('animate__animated', ...dmx.animate.effects.map(effect => 'animate__' + effect));
};

dmx.animate.effects = [
  // Attension seekers
  'bounce', 'flash', 'pulse', 'rubberBand', 'shakeX', 'shakeY', 'headShake', 'swing', 'tada', 'wobble', 'jello', 'heartBeat',
  // Back entrances
  'backInDown', 'backInLeft', 'backInRight', 'backInUp',
  // Back exits
  'backOutDown', 'backOutLeft', 'backOutRight', 'backOutUp',
  // Bouncing entrances
  'bounceIn', 'bounceInDown', 'bounceInLeft', 'bounceInRight', 'bounceInUp',
  // Bouncing exits
  'bounceOut', 'bounceOutDown', 'bounceOutLeft', 'bounceOutRight', 'bounceOutUp',
  // Fading entrances
  'fadeIn', 'fadeInDown', 'fadeInDownBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight', 'fadeInRightBig',
  'fadeInUp', 'fadeInUpBig', 'fadeInTopLeft', 'fadeInTopRight', 'fadeInBottomLeft', 'fadeInBottomRight',
  // Fading exits
  'fadeOut', 'fadeOutDown', 'fadeOutDownBig', 'fadeOutLeft', 'fadeOutLeftBig', 'fadeOutRight', 'fadeOutRightBig',
  'fadeOutUp', 'fadeOutUpBig', 'fadeOutTopLeft', 'fadeOutTopRight', 'fadeOutBottomLeft', 'fadeOutBottomRight',
  // Flippers
  'flip', 'flipInX', 'flipInY', 'flipOutX', 'flipOutY',
  // Lightspeed
  'lightSpeedInRight', 'lightSpeedInLeft', 'lightSpeedOutRight', 'lightSpeedOutLeft',
  // Rotating entrances
  'rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight',
  // Rotating exits
  'rotateOut', 'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft', 'rotateOutUpRight',
  // Specials
  'hinge', 'jackInTheBox', 'rollIn', 'rollOut',
  // Zooming entrances
  'zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp',
  // Zooming exits
  'zoomOut', 'zoomOutDown', 'zoomOutLeft', 'zoomOutRight', 'zoomOutUp',
  // Sliding entrances
  'slideInDown', 'slideInLeft', 'slideInRight', 'slideInUp',
  // Sliding exits
  'slideOutDown', 'slideOutLeft', 'slideOutRight', 'slideOutUp'
];

dmx.animate.observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    const { inview, outview } = entry.target;

    entry.target.ratio = entry.intersectionRatio;

    //if (!entry.isIntersecting) return;

    if (!entry.target.animateVisible) {
      entry.target.style.setProperty('visibility', 'hidden');
    }

    if (entry.intersectionRatio >= inview.ratio) {
      if (!entry.target.animateVisible) {
        entry.target.animating = true;
        entry.target.animateVisible = true;
        entry.target.style.removeProperty('visibility');

        dmx.animate(entry.target, inview.effect, inview.duration, inview.delay).then(() => {
          entry.target.animating = false;
          entry.target.style.removeProperty('visibility');
        }).catch(() => {});
      }
    } else {
      if (outview && entry.target.animateVisible) {
        entry.target.animating = true;
        entry.target.animateVisible = false;
        
        dmx.animate(entry.target, outview.effect, outview.duration, outview.delay).then(() => {
          entry.target.animating = false;
          entry.target.style.setProperty('visibility', 'hidden');
        }).catch(() => {});
      }
    }
  });
}, {
  threshold: [0,.1,.2,.3,.4,.5,.6,.7,.8,.9,1]
});
